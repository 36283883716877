import React, { useContext, useEffect, useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { Link } from "react-router-dom";
import { Context as AppContext } from "../context/appContext";
import "./InvoicesView.css";

const InvoicesView = () => {
  const { state, getInvoices } = useContext(AppContext);

  useEffect(() => {
    getInvoices();
  }, []);

  const columns = useMemo(
    () => [
      { Header: "Invoice ID", accessor: "id" },
      { Header: "Invoice Number", accessor: "properties.hs_number" },
      { Header: "Amount Billed", accessor: "properties.hs_amount_billed" },
      { Header: "Balance Due", accessor: "properties.hs_balance_due" },
      { Header: "Due Date", accessor: "properties.hs_due_date" },
      { Header: "Created Date", accessor: "properties.hs_createdate" },
      {
        Header: "Last Modified Date",
        accessor: "properties.hs_lastmodifieddate",
      },
      { Header: "Status", accessor: "properties.hs_invoice_status" },
      {
        Header: "Actions",
        id: "actions",
        accessor: "id",
        Cell: ({ value }) => (
          <Link to={`/invoice/${value}`}>
            Preview
          </Link>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => state.invoices || [], [state.invoices]);

  const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  if (!state.invoices) {
    return <p>Loading invoices...</p>;
  }

  return (
    <div className="invoices-container">
      <h1>Invoices</h1>
      {data.length > 0 ? (
        <>
          {/* <div className="table-responsive"> */}
          <table {...getTableProps()} className="invoices-table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      key={column.id}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {/* <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr key={row.id} {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td key={cell.column.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody> */}
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr key={row.id} {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        key={cell.column.id}
                        {...cell.getCellProps()}
                        data-label={cell.column.Header}
                      >
                        {truncateText(cell.render("Cell"), 50)}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* </div> */}
          <div className="pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span>
              | Go to page:{" "}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "50px" }}
              />
            </span>{" "}
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </>
      ) : (
        <p>No invoices available.</p>
      )}
    </div>
  );
};

export default InvoicesView;
