import React, { useContext, useEffect, useState, useRef } from "react";
import { Context as AppContext } from "../context/appContext";
import SignatureCanvas from "react-signature-canvas";
import "./invoicePreview.css";

const InvoicePreview = () => {
  const { state, getInvoices, getInvoiceCustomerData, getCustomers } = useContext(AppContext);
  const [invoiceData, setInvoiceData] = useState(null);
//   const [customerData, setCustomerData] = useState(null);

  const [signature, setSignature] = useState(null);
  const sigPad = useRef({});

  const clearSignature = () => {
    if (sigPad.current) {
      sigPad.current.clear();
    }
    setSignature(null);
  };

  const saveSignature = () => {
    setSignature(sigPad.current.getTrimmedCanvas().toDataURL("image/png"));
  };

  useEffect(() => {
    // console.log(state);
    if (!state.invoices) {
      getInvoices();
    }
    if (!state.customers) {
      getCustomers();
    }
    // get invoiceid from /invoice/:invoiceId
    const invoiceId = window.location.pathname.split("/").pop();
    console.log(invoiceId);
    try {
      const invoice = state.invoices.find((inv) => inv.id === invoiceId);
      console.log(invoice);
      setInvoiceData(invoice);
    } catch (error) {
      console.error("Failed to fetch invoice data:", error);
    }
    getInvoiceCustomerData(invoiceId);
  }, []);

  const handleCancel = () => {
    window.history.back();
  };

  console.log(state);

  if (!invoiceData) {
    return <div>No invoice data available.</div>;
  }

  return (
    <div className="invoice-preview">
      <h2>Invoice Preview {invoiceData.properties.hs_number}</h2>
      <div className="clinic-info">
        <h3>{invoiceData.properties.clinic_name || "Customer Name"}</h3>
        <p>{invoiceData.properties.clinic_address || "Customer Address"}</p>
        <p>{invoiceData.properties.clinic_phone || "Customer Phone"}</p>
      </div>
      <div className="billing-details">
        <h3>Billing Details</h3>
        <div className="billing-grid">
          <div>
            <strong>Bill To</strong>
            <p>{invoiceData.properties.customer_name || "Customer Name"}</p>
          </div>
          <div>
            <strong>Invoice Number</strong>
            <p>{invoiceData.properties.hs_number}</p>
          </div>
          <div>
            <strong>Invoice Date</strong>
            <p>{invoiceData.properties.hs_createdate || "N/A"}</p>
          </div>
          <div>
            <strong>Payment Method</strong>
            <p>{invoiceData.properties.payment_method || "N/A"}</p>
          </div>
          <div>
            <strong>Payment Date</strong>
            <p>{invoiceData.properties.payment_date || "N/A"}</p>
          </div>
          <div>
            <strong>Paid Via</strong>
            <p>{invoiceData.properties.paid_via || "N/A"}</p>
          </div>
          <div>
            <strong>Transaction ID</strong>
            <p>{invoiceData.properties.transaction_id || "N/A"}</p>
          </div>
          <div>
            <strong>Receipt ID</strong>
            <p>{invoiceData.properties.receipt_id || "N/A"}</p>
          </div>
          <div>
            <strong>Address</strong>
            <p>{invoiceData.properties.customer_address || "N/A"}</p>
          </div>
        </div>
      </div>
      <div className="invoice-items">
        <h3>Line Item Details</h3>
        <table>
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Amount</th>
              <th>Quantity</th>
              {/* <th>Discount</th> */}
              {/* <th>Tax</th> */}
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {invoiceData.lineItemData.map((item, index) => (
              <tr key={index}>
                <td>{item.properties.name}</td>
                <td>${item.properties.amount}</td>
                <td>{item.properties.quantity || 1}</td>
                {/* <td>${item.properties.discount}</td> */}
                {/* <td>${item.properties.tax}</td> */}
                <td>
                  ${item.properties.amount * (item.properties.quantity || 1)}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="3">
                <strong>Total</strong>
              </td>
              <td>
                <strong>${invoiceData.properties.hs_amount_billed}</strong>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="footer-text">
        <p>The text configured and decided by the practice owner/admin</p>
      </div>
      <div className="digital-signature">
        <h3>Digital Signature</h3>
        {signature ? (
          <div>
            <img src={signature} alt="Signature" />
            <button onClick={clearSignature}>Clear Signature</button>
          </div>
        ) : (
          <div>
            <SignatureCanvas
              ref={sigPad}
              canvasProps={{
                className: "signature-canvas",
              }}
            />
            <button onClick={clearSignature}>Clear</button>
            <button onClick={saveSignature}>Save signature</button>
          </div>
        )}
      </div>
      <div className="action-buttons">
        <button onClick={handleCancel} className="cancel-button">
          Cancel
        </button>
        <button className="print-button">Save Invoice</button>
      </div>
    </div>
  );
};

export default InvoicePreview;
