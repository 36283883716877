import React, { useState } from 'react';

const PaymentScreen = () => {
  const [device, setDevice] = useState('');
  const [customer, setCustomer] = useState('');
  const [invoice, setInvoice] = useState('');

  const handleDeviceChange = (event) => {
    setDevice(event.target.value);
  };

  const handleCustomerChange = (event) => {
    setCustomer(event.target.value);
  };

  const handleInvoiceChange = (event) => {
    setInvoice(event.target.value);
  };

  return (
    <div>
      <h1>Payment Screen</h1>
      <form>
        <label>
          Device:
          <select value={device} onChange={handleDeviceChange}>
            <option value="">Select a device</option>
            <option value="laptop">Laptop</option>
            <option value="tablet">Tablet</option>
            <option value="smartphone">Smartphone</option>
          </select>
        </label>
        <br />
        <label>
          Customer:
          <select value={customer} onChange={handleCustomerChange}>
            <option value="">Select a customer</option>
            <option value="customer1">Customer 1</option>
            <option value="customer2">Customer 2</option>
            <option value="customer3">Customer 3</option>
          </select>
        </label>
        <br />
        <label>
          Invoice:
          <select value={invoice} onChange={handleInvoiceChange}>
            <option value="">Select an invoice</option>
            <option value="invoice1">Invoice 1</option>
            <option value="invoice2">Invoice 2</option>
            <option value="invoice3">Invoice 3</option>
          </select>
        </label>
        <br />
        <button type="submit">Proceed to Payment</button>
      </form>
    </div>
  );
};

export default PaymentScreen;
