import React, { useEffect, useContext, useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { Context as AppContext } from "../context/appContext";
import "./CustomersComponent.css";
import LogOut from "./logOut";


const CustomersComponent = () => {
  const { state, getCustomers, getAccessToken } = useContext(AppContext);

  const handleRefresh = async () => {
    const accessToken = document.cookie
      .split("; ")
      .find((row) => row.startsWith("authToken="))
      .split("=")[1];
    await getAccessToken(accessToken);
  };
  
  // const memoizedHandleRefresh = useMemo(() => handleRefresh, [getAccessToken]);

  useEffect(() => {
    // memoizedHandleRefresh();
    getCustomers();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: (row) =>
          `${row.properties.firstname} ${row.properties.lastname}`,
      },
      { Header: "Email", accessor: "properties.email" },
      { Header: "Phone", accessor: "properties.phone" },
      { Header: "Company", accessor: "properties.company" },
    ],
    []
  );

  const data = useMemo(() => state.customers, [state.customers]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  if (state.apiError) {
    return (
      <> 
        <LogOut />
      </>
    )
  }

  if (!state.customers) {
    return <div>Loading...</div>;
  }

  return (
    <div className="customers-container">
      <h1>Customers</h1>
      <table {...getTableProps()} className="customers-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} data-label={cell.column.Header}>
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "50px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default CustomersComponent;
