import React, { useContext } from 'react';
import { Context as AppContext } from '../context/appContext';

const Logout = () => {
  const { state, logout, getAccessToken, updateApiError } = useContext(AppContext);

  const handleLogout = async () => {
    await logout();
    // Redirect to home page or login page after logout
    window.location.href = '/';
  };

  const handleRefresh = async () => {
    const accessToken = document.cookie.split('; ').find(row => row.startsWith('authToken=')).split('=')[1];
    await getAccessToken(accessToken);
    updateApiError(null);
  };

  return (
    <>
    {state.apiError && <div className="alert alert-danger">{state.apiError}</div>}
    <button className='btn btn-danger' onClick={handleLogout}>
      Logout
    </button>
    <button className='btn btn-primary' onClick={handleRefresh}>
      Refresh Access Token
    </button>
    </>
    
  );
};

export default Logout;
