import React, { useContext, useEffect } from "react";
import { Context as AppContext } from "../context/appContext";

const UserInfoConponent = () => {
  const { state, getTokenStatus, checkAuthCookie } = useContext(AppContext);

  useEffect(() => {
    checkAuthCookie().then(() => {
      getTokenStatus();
    });
  }, []);

  if (state.user !== null) {
    return (
      <div>
        <h1>{state.user.user}</h1>
        <div style={{ margin: '10px 0' }}>
          <h2>User ID: {state.user.user_id}</h2>
          <h2>Token Type: {state.user.token_type}</h2>
        </div>
        <h1>{state.user.hub_domain}</h1>
        <h1>Scopes</h1>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {state.user.scopes ? state.user.scopes.map((scope, index) => (
            <div
              key={index}
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "10px",
                margin: "5px",
                minWidth: "200px",
              }}
            >
              <h3>Scope {index + 1}</h3>
              <p>{scope}</p>
            </div>
          )) : null}
        </div>
      </div>
    );
  }

  return (
    <div>
      <h1>No user</h1>
    </div>
  );
};

export default UserInfoConponent;
