import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';

const NavBar = () => {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/"><img src="logo.png" alt="Logo" style={{ maxHeight: '50px', maxWidth: '50px' }} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/payments">Take Payment</Nav.Link>
            <Nav.Link href="/customers">Customers</Nav.Link>
            {/* <Nav.Link href="/reports">Reports</Nav.Link> */}
            {/* <Nav.Link href="/bath-renovation">Bath Renovation</Nav.Link> */}
            <Nav.Link href="/invoices">Invoices</Nav.Link>
            <Nav.Link href="/userinfo">User Info</Nav.Link>
            <Nav.Link href="/logout">Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
